<template>
  <div class="login-view bg-healex-gradient">
    <b-container>
      <div class="box text-center">
        <b-card class="inner p-2 p-md-5 shadow">
          <img class="helex-log" src="@/assets/logo.png" />
          <simple-loader />
        </b-card>
      </div>
      <b-modal
        id="modal-concurrency"
        :title="$t('login.anotherSessionOpened.title')"
        @close="logOut"
        >{{ $t("login.anotherSessionOpened.description") }}
        <template v-slot:modal-footer>
          <slot name="footer">
            <b-button @click="logOut" :disabled="okLoading">{{
              $t("general.cancel")
            }}</b-button
            ><b-button
              variant="primary"
              @click="handleOk"
              :disabled="okLoading"
              >{{ $t("general.ok") }}</b-button
            ></slot
          >
        </template>
      </b-modal>
    </b-container>
  </div>
</template>

<script>
// import router from "../router";
// import axios from "axios";
// import qs from "qs";
import { mapActions, mapGetters } from "vuex";

export default {
  methods: {
    ...mapActions({
      smsLogin: "SMSLOGIN",
      getSubMenu: "GET_SUBMENU",
      finishAllSessions: "FINISHALLSESSIONS",
      onLogout: "LOGOUT",
    }),
    handleOk() {
      const that = this;
      this.$bvModal.hide("modal-concurrency");
      this.finishAllSessions({ api: this.$api }).finally(() => {
        if (that.concurrency === false) {
          this.$store.dispatch("GET_SUBMENU", { api: this.$api });
          that.$router.push("/");
        }
      });
    },
    logOut() {
      this.$bvModal.hide("modal-concurrency");
      this.onLogout();
      this.$router.push("/login");
    },
  },
  computed: {
    ...mapGetters({
      message: "GET_MESSAGE",
      concurrency: "GET_CONCURRENCY",
    }),
  },
  async created() {
    const that = this;
    this.$oidcClient
      .signinRedirect(this.$route)
      .then((userInfo) => {
        this.smsLogin({
          api: that.$api,
          accessToken: userInfo.access_token,
        }).then(
          () => {
            if (that.concurrency === false) {
              that.$router.push("/");
            } else {
              that.$bvModal.show("modal-concurrency");
            }
          },
          (error) => {
            console.log("Error: ", error);
            that.$bvToast.toast(error, {
              variant: "danger",
              title: "Login Error",
              autoHideDelay: 5000,
              appendToast: true,
            });
          }
        );
      })
      .catch((err) => {
        console.log(err);
      });
  },
};
</script>

<style scoped>
.login-view {
  position: relative;
  width: 100%;
  height: 100%;
  background: #dedede;
}

.login-view .box {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.login-view .box .inner {
  position: relative;
  width: 500px;
  max-width: 100%;
}

.login-view .helex-log {
  display: block;
  max-width: 200px;
  height: auto;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 3rem;
}
</style>
